import './style/HeroVisual.css';
import React from 'react';
import Factory from '../../media/factory-32@3x.png';
import Timelapse from '../../media/timelapse-32@12x.png';
import ShipBox from '../../media/ship-box-32@6x.png';
import Dollar from '../../media/dollar-yellow64@3x.png';
const HeroVisual = ({ width = 300, height = 300 }) => {
  // Center of the SVG
  const centerX = width / 2;
  const centerY = height / 2;

  // Circle radius for manufacturer nodes
  const radius = width / 3; // Fixed radius for positioning manufacturer nodes

  // Angles for manufacturer nodes (in degrees)
  const angles = [0, 120, 240]; // Equidistant angles around the circle

  // Function to convert degrees to radians
  const degToRad = (deg) => (deg * Math.PI) / 180;

  // Calculate manufacturer positions based on angles
  const manufacturerPositions = angles.map((angle) => {
    const rad = degToRad(angle);
    return {
      x: centerX + radius * Math.cos(rad),
      y: centerY + radius * Math.sin(rad),
    };
  });

  // Static sizes and styling attributes
  const manufacturerCircleRadius = 22;
  const strokeWidth = 42;
  const manufacturerLabelOffsetY = -48;
  const cornerRadius = 5;

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Define the chevron shape to be used in animations */}
      <defs>
        <g id="chevronShape">
          <image
            x={-17}
            y={-17}
            width={34}
            height={34}
            href={ShipBox}
            >
          </image>
        </g>
        <linearGradient id="gradient1" x1="0%" y1="100%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "#9BB0FF", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: "#FFF", stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <circle
          cx={centerX}
          cy={centerY}
          r={width / 3}
          fill="black"
      />     
      {manufacturerPositions.map((pos, index) => {
        const nextPos =
          manufacturerPositions[(index + 1) % manufacturerPositions.length];
        const gradientId = `arcGrad${index}`;
        return (
          <React.Fragment key={`arc-fragment${index}`}>
            {/* Define a gradient for this arc */}
            <defs>
              <linearGradient
                id={gradientId}
                gradientUnits="userSpaceOnUse"
                x1={pos.x}
                y1={pos.y}
                x2={nextPos.x}
                y2={nextPos.y}
              >
                <stop offset="0%" stopColor="#fff" stopOpacity="0" />
                <stop offset="50%" stopColor="#fff" stopOpacity=".4" />
                <stop offset="100%" stopColor="#fff" stopOpacity="0" />
              </linearGradient>
            </defs>
            {/* Draw the arc using the gradient for the stroke */}
            <path
              id={`arc${index}`}
              d={`M${pos.x},${pos.y} A${radius},${radius} 0 0,1 ${nextPos.x},${nextPos.y}`}
              stroke={`url(#${gradientId})`}
              strokeWidth={strokeWidth}
              fill="none"
            />
          </React.Fragment>
        );
      })}

      {/* Chevrons Between Manufacturers */}
      {manufacturerPositions.map((_, index) => (
        <use key={`chevronArc${index}`} xlinkHref="#chevronShape">
          <animateMotion
            // rotate="auto"
            dur="16s"
            repeatCount="indefinite"
            begin={`0s`}
          >
            <mpath xlinkHref={`#arc${index}`} />
          </animateMotion>
        </use>
      ))}

      <image
        x={centerX - (width * .43) / 2}
        y={centerY - (width * .43) / 2}
        width={width * .43}
        height={width * .43}
        href={Timelapse}
        >
        <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from={`0 ${centerX} ${centerY}`}
            to={`-360 ${centerX} ${centerY}`}
            dur="124s"
            repeatCount="indefinite"
        />
        </image>
      <image
        x={centerX - 20}
        y={centerY - 20}
        width={40}
        height={40}
        href={Dollar}
      >
      </image>
      {/* Manufacturer Circles and Labels */}
      {manufacturerPositions.map((pos, index) => {
        return (
          <React.Fragment key={`manufacturer${index}`}>
              <rect
                x={pos.x - manufacturerCircleRadius}
                y={pos.y - manufacturerCircleRadius}
                width={manufacturerCircleRadius * 2}
                height={manufacturerCircleRadius * 2}
                fill="#000"
                rx={cornerRadius}
                ry={cornerRadius}
              />
            <image
                x={pos.x - 17}
                y={pos.y - 17}
                width={34}
                height={34}
                href={Factory}
            >
            </image>
          </React.Fragment>
        );
      })}
    </svg>
  );
};

export default HeroVisual;