import React, { useState, useEffect, useRef } from 'react';
import './style/Landing.css';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../component/block/Button';
import { setCookies, setInviteCode } from '../redux/localSlice';
import LogRocket from 'logrocket';
import Section from '../component/block/Section';
import Tile from '../component/block/Tile';
import Label from '../component/block/Label';
import Divider from '../component/block/Divider';
import ScreenLock from '../media/screen-unlock-72@3x.png';
import GlobeShield from '../media/globe-shield-72@3x.png';
import SpeedBullet from '../media/speed-bullet-72@3x.png';
import UploadDocument from '../media/upload-document-72@3x.png';
import MarketVisual from '../media/market-visual-482x100@3x.png';
import TransformVisual from '../media/transform-visual-482x330@3x.png';
import AddMember from '../media/add-member-72@3x.png';
import SliderIndicator from '../media/slider-indicator-72@3x.png';
import InviteVisual from '../media/invite-visual-336x110@3x.png';
import MinimumVisual from '../media/minimum-visual-168-94@3x.png';
import Blinds from '../media/blinds-72@3x.png';
import PageBars from '../media/page-bars-72@3x.png';
import SignPost from '../media/sign-post-72@3x.png';
import ShieldCheck from '../media/shield-check-72@3x.png';
import Logo from '../media/logo-72@3x.png';
import ShippingVisual from '../media/shipping-visual-482x122@3x.png';
import ShippingVisualSmall from '../media/shipping-visual-190x122@3x.png';
import Printer from '../media/printer-72@3x.png';
import MailUser from '../media/mail-user-72@3x.png';
import Plaid from '../media/plaid-72@3x.png';
import NoVisibility from '../media/no-visibility-72@3x.png';
import Converge from '../media/converge-72@3x.png';
import BarGraph from '../media/bar-graph-72@3x.png';
import XBin from '../media/x-bin-72@3x.png';
import ShieldTransfer from '../media/shield-transfer-72@3x.png';
import Barak from '../media/barak-72@3x.png';
import Andres from '../media/andres-72@3x.png';
import John from '../media/john-72@3x.png';
// import Wordmark from '../media/wordmark-142x20@3x.png';
import Wordmark from '../media/materialize-logo-wordmark-black.png';
import SlipVisual from '../media/slip-visual-482x122@3x.png';
import SlipVisualSmall from '../media/slip-visual-261x122@3x.png';
import TextBlock from '../component/block/TextBlock';
import StepSection from '../component/block/StepSection';
import ImageTextBlock from '../component/block/ImageTextBlock';
import TestimonialCard from '../component/block/TestimonialCard';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Signup from '../component/block/Signup';
import CollapsableSection from '../component/block/CollapsableSection';
import HeroVisual from '../component/block/HeroVisual';
import CookieSettings from '../component/block/CookieSettings';
import Clarity from '@microsoft/clarity';
import posthog from 'posthog-js'

const Landing = () => {
    const local = useSelector((state) => state.local);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showCookieModal, setShowCookieModal] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [cookieModalVisible, setCookieModalVisible] = useState(false);
    const timeoutRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const { search } = useLocation();
    const searchParams = Object.fromEntries(new URLSearchParams(search));
    const { page } = useParams();

    const isLocalhost = window.location.origin.includes('localhost') ? true : false;
    if (!isLocalhost) {
        Clarity.init("pyk6kd2un8");
        posthog.init('phc_NGGCjf8a2ZLnMRrPhBqOI0pD9UbnTQklPmSsuGNxzEb',
            {
                api_host: 'https://us.i.posthog.com',
                person_profiles: 'always' // or 'identified_only' to not create profiles for anonymous users
            }
        );
    }

    const handleSetCookies = (setting) => {
        if (setting === "all" && !window.location.origin.includes("localhost")) {
            const scriptOne = document.createElement("script");
            scriptOne.src = "https://www.googletagmanager.com/gtag/js?id=G-F9HDQ6FYX5";
            scriptOne.async = true;
            document.head.appendChild(scriptOne);

            const scriptTwo = document.createElement("script");
            scriptTwo.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-F9HDQ6FYX5');
            `;
            document.head.appendChild(scriptTwo);

            LogRocket.init('o4yyvv/materialize-landing');

            handleHideCookieModal();
        }

        dispatch(setCookies(setting));
    }

    useEffect(() => {
        if (document.fonts) {
          const overlay = document.getElementById('overlay');
 
          document.fonts.ready.then(() => {
            if (overlay) {
              overlay.classList.add('fade-out');
            }
          });
        }
    }, []);

    useEffect(() => {
        if (page === 'login' && searchParams.code) {
            dispatch(setInviteCode(searchParams.code));
            navigate('/');
            handleSetCookies("all");
        }
    }, [dispatch, navigate, page, searchParams]);

    const handleShowModal = () => {
      // Clear any existing timeout to prevent state issues when reopening the modal quickly
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setModalVisible(true);
      setShowModal(true);
      setMenuOpen(false);
      const landing = document.getElementById('landing');
      if (landing) {
        landing.classList.add('overflow-y-hidden');
      }
    };
  
    const handleHideModal = () => {
      setShowModal(false);    
      timeoutRef.current = setTimeout(() => {
        setModalVisible(false);
        const landing = document.getElementById('landing');
        if (landing) {
          landing.classList.remove('overflow-y-hidden');
        }
      }, 700); // exit animation duration
    };

    const handleShowCookieModal = () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setCookieModalVisible(true);
        setShowCookieModal(true);
        const landing = document.getElementById('landing');
        if (landing) {
          landing.classList.add('overflow-y-hidden');
        }
      };
    
      const handleHideCookieModal = () => {
        setShowCookieModal(false);    
        timeoutRef.current = setTimeout(() => {
          setCookieModalVisible(false);
          const landing = document.getElementById('landing');
          if (landing) {
            landing.classList.remove('overflow-y-hidden');
          }
        }, 700);
      };

    const strings = {
        signup: "Signup",
        getStarted: "Get Started",
        blockOne: {
            title: "Unlock the value of your excess inventory.",
            subtitle: "Sell your excess inventory privately without the hassle.",
            login: "Login",
            shop: "Shop",
        },
        blockTwo: {
            featureOne: {
                title: "Address excess proactively.",
                subtitle: "Timing is everything. List excess early to achieve higher returns and prevent depreciation."
            },
            featureTwo: {
                title: "Built for all manufacturers.",
                subtitle: "We partner exclusively with verifiable manufacturers to ensure quality and trust within our marketplace."
            },
            featureThree: {
                title: "No upfront cost.",
                subtitle: "Our platform is free to use for any size team or inventory, with a transparent transaction fee when your items sell."
            }
        },
        blockThree: {
            title: "How it works.",
        },
        blockFour: {
            title: "Setup.",
            subtitle: "No complicated integrations needed.",
            featureOne: {
                title: "Analyze your inventory.",
                subtitle: "Easily upload your excess inventory in bulk. Our system automatically sources market data for every part number to enhance your listings."
            },
            featureTwo: {
                title: "Invite your team.",
                subtitle: "Assign roles and permissions for seamless collaboration."
            },
            featureThree: {
                title: "Set order minimums.",
                subtitle: "Set a minimum value to avoid inconvenient orders."
            }
        },
        blockFive: {
            title: "Accept order requests.",
            subtitle: "Control every transaction with confidence.",
            featureOne: {
                title: "Wide Exposure.",
                subtitle: "Your inventory reaches over 300+ public and private channels, connecting you with a vast network of potential buyers."
            },
            featureTwo: {
                title: "Evaluate Requests Wisely.",
                subtitle: "Leverage extensive market data against your unit costs to make informed decisions on each request."
            },
            featureThree: {
                title: "No Obligations.",
                subtitle: "You're in control - accept only the requests that meet your goals. Decline any offer without penalties."
            },
            featureFour: {
                title: "Secure Transactions.",
                subtitle: "Buyers pay upfront, and funds are securely held until the successful receipt of goods."
            }
        },
        blockSix: {
            title: "Pack and ship.",
            subtitle: "Effortless logistics and secure delivery.",
            featureOne: {
                title: "Prepaid shipping labels.",
                subtitle: "We cover all shipping costs. Simply print the prepaid labels we provide and attach them to your packages."
            },
            featureTwo: {
                title: "Designated shipping contacts.",
                subtitle: "Assign your shipping team to receive labels and packing slips directly, streamlining the process."
            }
        },
        blockSeven: {
            title: "Get paid.",
            subtitle: "Fast, secure, and transparent payments.",
            featureOne: {
                title: "Automatic payments, secured by Plaid.",
                subtitle: "Set up your payment information easily and securely using Plaid."
            },
            featureTwo: {
                title: "Data security.",
                subtitle: "Your banking details are encrypted and never stored on our servers, ensuring complete privacy."
            }
        },
        blockEight: {
            title: "Why choose us?",
            featureOne: {
                title: "Private & Secure",
                subtitle: "We handle all transactions and communications, so your organization's identity remains confidential."
            },
            featureTwo: {
                title: "Simplify processes.",
                subtitle: "Our platform handles everything from market analysis to shipping logistics, so you can focus on your core business."
            },
            featureThree: {
                title: "Maximize returns.",
                subtitle: "Selling directly to other manufacturers reclaims on average 30% more than selling to brokers or liquidators."
            },
            featureFour: {
                title: "Reduce waste.",
                subtitle: "Keep valuable parts in use and out of landfills, and feel good about participating in the circular economy."
            }
        },
        blockNine: {
            title: "Ready to start?",
            subtitle: "You're moments away from maximizing the reclamation value of your excess inventory."
        },
        blockTen: {
            title: "What our customers say.",
            testimonialOne: {
                name: "John Malone",
                company: "Kittyhawk",
                role: "Director of Manufacturing",
                testimonial: "Materialize helped us find meaningful revenue from a wide variety of our excess parts with little effort. A dedicated partner has been crucial with obsolescence being an ongoing challenge!",
            },
            testimonialTwo: {
                name: "Andres Pena",
                company: "Raymond",
                role: "Inventory Control Manager",
                testimonial: "Materialize transformed our obsolete inventory into revenue, allowing us to focus on our main operations without interruptions. They offer essential support and insights on the materials you need to sell.",
            }
        },
        blockEleven: {
            title: "Frequently asked questions.",
            questions: [
                {
                    question: "How do you verify manufacturers?",
                    answer: "We conduct thorough KYC checks, including verifying business registrations and credentials."
                },
                {
                    question: "What if I have items that are hard to categorize?",
                    answer: "Our market analysis tools help normalize and categorize your inventory to create rich product pages, even for unique items."
                },
                {
                    question: "What does it cost?",
                    answer: "Using our platform is free. We charge a percentage-based transaction fee when items sell."
                },
                {
                    question: "Can you handle multiple warehouses?",
                    answer: "Absolutely. Our system is designed to handle multiple warehouses, locations, and part numbers throughout."
                }
            ]
        },
        footer: {
            company: "Materialize",
            address: "651 N Broad St, Suite 201 - Middletown, DE 19709",
            linkedin: "LinkedIn",
            twitter: "Twitter/X",
            email: "info@trymaterialize.com"
        }
    }

    return (
        <>
            {cookieModalVisible && <CookieSettings handleSetCookies={handleSetCookies} showCookieModal={showCookieModal} handleHideCookieModal={handleHideCookieModal} classes={`${showCookieModal ? "fade-in" : "fade-out no-pointer"}`} />}
            {modalVisible && <Signup handleHideModal={handleHideModal} showModal={showModal} classes={`${showModal ? "fade-in" : "fade-out no-pointer"}`} />}
            {/* <a id="scroll-top" className="scroll-top" href="#home"><Image source={ArrowUp} fullHeight={true} fullWidth={true} whiteBg={false} /></a> */}
            <div id="landing" className="vertical flex overflow-y-scroll fade-slide-in">
                <div id="overlay" />
                <Section
                    bg="yellow"
                    noPadTop={true}
                    noPadBot={true}
                    card={false}
                    header={true}
                    classes={""}
                    content={
                        <>
                            <Section
                                bg="yellow"
                                noPadTop={true}
                                noPadBot={true}
                                header={true}
                                content={
                                    <>
                                        <div id="home" className="yellow-bg flex horizontal flex-1 space-between pad-xl">
                                            <img src={Wordmark} alt="Materialize Wordmark" className="wordmark-image" onClick={() => window.location.href = "/"} />
                                            <div id="menu" className="flex vertical align-center justify-center desktop-hide" onClick={() => setMenuOpen(!menuOpen)}>
                                                <div className={`menu-bar ${menuOpen ? "open" : ""}`} />
                                                <div className={`menu-bar ${menuOpen ? "open" : ""}`} />
                                            </div>
                                            <div id="menu-links" className={`flex horizontal mobile-vertical h-gap-l align-center ${!menuOpen ? "mobile-hide" :  "pad-xl v-gap-xl"}`}>
                                                {local?.inviteCode === "" && <button className="sf-mono uppercase size-s bold" onClick={handleShowModal}>{strings.signup}</button>}
                                                {local?.inviteCode === "" && <button className="sf-mono uppercase size-s bold" onClick={() => window.location.href = "https://app.trymaterialize.com"}>{strings.blockOne.login}</button>}
                                                {local?.inviteCode !== "" && <button className="sf-mono uppercase size-s bold" onClick={() => window.location.href = `https://app.trymaterialize.com/login?code=${local?.inviteCode}`}>Dashboard</button>}
                                                <button className="sf-mono uppercase size-s bold" onClick={() => window.location.href = "https://shop.trymaterialize.com"}>{strings.blockOne.shop}</button>
                                            </div>
                                        </div>
                                    </>
                                }
                            />
                            <div className="flex horizontal mobile-vertical reverse flex-1 pad-xl h-gap-xl align-end mobile-align-center no-pad-top">
                                <div className="flex vertical flex-2 v-pad-xl no-pad-bot mobile-no-pad-top">
                                    <div className="flex vertical flex-2 v-gap-l v-pad-m">
                                        <div className="flex vertical flex-2 v-gap-s">
                                            <Label
                                                content={strings.blockOne.title}
                                                color="black"
                                                displayFont={true}
                                                size="display"
                                                bold={true}
                                                classes="fade-slide-in-d2 poppins"
                                            />
                                            <Label
                                                content={strings.blockOne.subtitle}
                                                color="black"
                                                mono={true}
                                                line="l"
                                                size="l"
                                                classes="fade-slide-in-d3"
                                            />
                                        </div>
                                        <div className="flex horizontal v-gap-s">
                                            {local?.inviteCode === "" && <button className="primary-button on-black bold size-m" onClick={handleShowModal}>{strings.getStarted}</button>}
                                            {local?.inviteCode !== "" && <button className="primary-button on-black bold size-m" onClick={() => window.location.href = `https://app.trymaterialize.com/login?code=${local?.inviteCode}`}>Go to Dashboard</button>}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex vertical flex-1 v-gap-xs">
                                {window.innerWidth < 768 ? (
                                    <HeroVisual width={240} height={240} />
                                ) : (
                                    <HeroVisual />
                                )}
                                </div>
                            </div>
                            <div className="flex horizontal pad-xl no-pad-bot no-pad-top">
                            </div>
                        </>
                    }
                />
                <Section
                    classes="white-bg"
                    noPadBot={true}
                    content={
                        <>
                            <div className="flex vertical v-pad-xl">
                                <div className="flex horizontal justify-center">
                                    <div className="flex horizontal mobile-vertical h-gap-xl v-gap-xl pad-xl content-width no-pad-top">
                                        <ImageTextBlock
                                            image={SpeedBullet}
                                            title={strings.blockTwo.featureOne.title}
                                            alt="Speed Icon"
                                            classes=" fade-slide-in-d4"
                                            subtitle={strings.blockTwo.featureOne.subtitle}
                                        />
                                        <ImageTextBlock
                                            image={GlobeShield}
                                            title={strings.blockTwo.featureTwo.title}
                                            alt="Globe Shield Icon"
                                            classes=" fade-slide-in-d3"
                                            subtitle={strings.blockTwo.featureTwo.subtitle}
                                        />
                                        <ImageTextBlock
                                            image={ScreenLock}
                                            title={strings.blockTwo.featureThree.title}
                                            alt="Screen Lock Icon"
                                            classes=" fade-slide-in-d2"
                                            subtitle={strings.blockTwo.featureThree.subtitle}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
                <Section
                    noPadTop={true}
                    noPadBot={true}
                    content={
                        <>
                            <div className="flex horizontal flex-1 h-gap-xs justify-center">
                                <div className="flex horizontal h-gap-xs justify-center content-width">
                                    <div className="flex yellow-bg pad-xs z-2 fade-slide-in-d">
                                        <Label
                                            content={strings.blockThree.title}
                                            displayFont={true}
                                            size="xxl"
                                            bold={true}
                                            color="black"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
                <Section
                    bg="grey"
                    noPadTop={true}
                    shiftUp={true}
                    noPadBot={true}
                    content={
                        <>
                            <StepSection
                                title={strings.blockFour.title}
                                subtitle={strings.blockFour.subtitle}
                                step={1}
                                bg="grey" />
                            <div className="flex horizontal mobile-vertical flex-1 h-gap-xl v-gap-xl pad-xl no-pad-top justify-center grey-bg">
                                <div className="flex vertical flex-1 v-gap-l">
                                    <ImageTextBlock
                                        image={UploadDocument}
                                        title={strings.blockFour.featureOne.title}
                                        alt="Upload List Icon"
                                        classes="no-grow"
                                        subtitle={strings.blockFour.featureOne.subtitle}
                                    />
                                    <img src={TransformVisual} alt="Screen Lock" className="visual-image" />
                                    <img src={MarketVisual} alt="Screen Lock" className="visual-image" />
                                </div>
                                <div className="flex vertical flex-1 h-gap-xl no-pad-top mobile-vertical v-gap-xl">
                                    <div className="flex horizontal flex-1 h-gap-l">
                                        <img src={AddMember} alt="Screen Lock" className="value-prop-image" />
                                        <div className="flex vertical flex-1 v-gap-l">
                                            <TextBlock
                                                title={strings.blockFour.featureTwo.title}
                                                subtitle={strings.blockFour.featureTwo.subtitle}
                                            />
                                            <img src={InviteVisual} alt="Screen Lock" className="visual-image" />
                                        </div>
                                    </div>
                                    <div className="flex horizontal flex-1 h-gap-l">
                                        <img src={SliderIndicator} alt="Screen Lock" className="value-prop-image" />
                                        <div className="flex vertical flex-1 v-gap-l">
                                            <TextBlock
                                                title={strings.blockFour.featureThree.title}
                                                subtitle={strings.blockFour.featureThree.subtitle}
                                            />
                                            <img src={MinimumVisual} alt="Screen Lock" className="visual-image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
                <Section
                    bg="white"
                    noPadTop={true}
                    content={
                        <>
                            <StepSection
                                title={strings.blockFive.title}
                                subtitle={strings.blockFive.subtitle}
                                step={2} />
                            <div className="flex horizontal flex-1 h-gap-xs justify-center">
                                <div className="flex vertical h-gap-xs v-pad-xl no-pad-top content-width">
                                    <div className="flex vertical h-gap-xl pad-xl v-gap-xl no-pad-top">
                                        <div className="flex horizontal mobile-vertical v-gap-xl flex-1 h-gap-xl">
                                            <ImageTextBlock
                                                image={Blinds}
                                                alt="Open Blinds Icon"
                                                title={strings.blockFive.featureOne.title}
                                                subtitle={strings.blockFive.featureOne.subtitle}
                                                vertical={false}
                                            />
                                            <ImageTextBlock
                                                image={PageBars}
                                                alt="Document with Bar Chart Icon"
                                                title={strings.blockFive.featureTwo.title}
                                                subtitle={strings.blockFive.featureTwo.subtitle}
                                                vertical={false}
                                            />
                                        </div>
                                        <div className="flex horizontal flex-1 h-gap-xl mobile-vertical v-gap-xl">
                                            <ImageTextBlock
                                                image={SignPost}
                                                alt="Sign Post with Arrows in Opposing Directions Icon"
                                                title={strings.blockFive.featureThree.title}
                                                subtitle={strings.blockFive.featureThree.subtitle}
                                                vertical={false}
                                            />
                                            <ImageTextBlock
                                                image={ShieldCheck}
                                                alt="Protective Shield with Checkmark Icon"
                                                title={strings.blockFive.featureFour.title}
                                                subtitle={strings.blockFive.featureFour.subtitle}
                                                vertical={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />      
                <Section
                    bg="blue"
                    noPadBot={true}
                    noPadTop={true}
                    content={
                        <>
                            <div className="flex vertical">
                                <StepSection
                                    title={strings.blockSix.title}
                                    subtitle={strings.blockSix.subtitle}
                                    step={3}
                                    bg="blue"
                                    classes="no-pad-bot" />
                                <div className="flex horizontal flex-1 h-gap-xs justify-center blue-bg">
                                    <div className="flex vertical h-gap-xs v-pad-xl no-pad-top no-pad-bot content-width">
                                        <div className="flex horizontal flex-1 pad-xl h-gap-xl no-pad-bot no-pad-top space-between mobile-hide">
                                            <div className="flex horizontal flex-1 space-between">
                                                <img src={ShippingVisual} alt="Shipping Label Visual" className="width-100 mobile-hide" />   
                                            </div>
                                            <div className="flex horizontal flex-1 space-between">
                                                <img src={SlipVisual} alt="Shipping Label Visual" className="width-100 mobile-hide" />
                                            </div>
                                        </div>
                                        <div className="flex horizontal flex-1 pad-xl h-gap-xl no-pad-bot no-pad-top space-between desktop-hide">
                                            <div className="flex horizontal flex-2 space-between">
                                                <img src={ShippingVisualSmall} alt="Shipping Label Visual" className="width-100 desktop-hide object-fit" />
                                            </div>
                                            <div className="flex horizontal flex-3 space-between">
                                                <img src={SlipVisualSmall} alt="Shipping Label Visual" className="width-100 desktop-hide object-fit" />   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
                <Section
                    bg="white"
                    noPadTop={true}
                    content={
                        <>
                            <div className="flex vertical">
                                <div className="flex horizontal flex-1 h-gap-xs justify-center white-bg no-pad-top">
                                    <div className="flex vertical h-gap-xs v-pad-xl no-pad-top content-width">
                                        <div className="flex horizontal justify-center mobile-vertical pad-xl no-pad-top v-gap-xl shift-value-prop-up h-gap-xl">
                                            <div className="flex horizontal mobile-vertical v-gap-xl h-gap-xl content-width no-pad-top">
                                                <div className="flex vertical v-gap-l">
                                                    <img src={Printer} alt="Printer Icon" className="value-prop-image" />
                                                    <TextBlock
                                                        title={strings.blockSix.featureOne.title}
                                                        subtitle={strings.blockSix.featureOne.subtitle}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex horizontal mobile-vertical v-gap-xl h-gap-xl content-width no-pad-top">
                                                <div className="flex vertical v-gap-l">
                                                    <img src={MailUser} alt="Printer Icon" className="value-prop-image" />
                                                    <TextBlock
                                                        title={strings.blockSix.featureTwo.title}
                                                        subtitle={strings.blockSix.featureTwo.subtitle}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
                <Section
                    bg="grey"
                    noPadTop={true}
                    content={
                        <>
                            <StepSection
                                title={strings.blockSeven.title}
                                subtitle={strings.blockSeven.subtitle}
                                step={4}
                                bg="grey" />
                            <div className="flex horizontal flex-1 h-gap-xs justify-center grey-bg">
                                <div className="flex flex-1 horizontal mobile-vertical h-gap-xl v-gap-xl pad-xl content-width no-pad-top">
                                    <ImageTextBlock
                                        image={Plaid}
                                        title={strings.blockSeven.featureOne.title}
                                        alt="Plaid Icon"
                                        subtitle={strings.blockSeven.featureOne.subtitle}
                                    />
                                    <ImageTextBlock
                                        image={NoVisibility}
                                        title={strings.blockSeven.featureTwo.title}
                                        alt="Eye with Line Through Icon"
                                        subtitle={strings.blockSeven.featureTwo.subtitle}
                                    />
                                </div>
                            </div>
                        </>
                    }
                />
                <Section
                    bg="white"
                    content={
                        <>
                            <div className="flex horizontal flex-1 pad-xl justify-center">
                                <Label
                                    content={strings.blockEight.title}
                                    displayFont={true}
                                    size="xl"
                                    bold={true}
                                    color="black"
                                />  
                            </div>
                            <div className="flex vertical flex-1 h-gap-xs justify-center v-gap-xl">
                                <div className="flex horizontal mobile-vertical h-gap-xl v-gap-xl pad-xl content-width no-pad-top">
                                    <ImageTextBlock
                                        image={ShieldTransfer}
                                        alt="Shield with Arrows Going Left and Right Icon"
                                        title={strings.blockEight.featureOne.title}
                                        subtitle={strings.blockEight.featureOne.subtitle}
                                        center={true}
                                    />
                                    <ImageTextBlock
                                        image={Converge}
                                        alt="Two Arrows Converging Icon"
                                        title={strings.blockEight.featureTwo.title}
                                        subtitle={strings.blockEight.featureTwo.subtitle}
                                        center={true}
                                    />
                                </div>
                                <div className="flex horizontal mobile-vertical h-gap-xl v-gap-xl pad-xl content-width no-pad-top">
                                    <ImageTextBlock
                                        image={BarGraph}
                                        alt="Bar Graph Icon"
                                        title={strings.blockEight.featureThree.title}
                                        subtitle={strings.blockEight.featureThree.subtitle}
                                        center={true}
                                    />
                                    <ImageTextBlock
                                        image={XBin}
                                        alt="Bin with X Icon"
                                        title={strings.blockEight.featureFour.title}
                                        subtitle={strings.blockEight.featureFour.subtitle}
                                        center={true}
                                    />
                                </div>
                            </div>
                        </>
                    }
                />
                <Section
                    bg="blue"
                    content={
                        <div className="flex vertical flex-1 pad-xl v-gap-xl align-center">
                            <div className="flex vertical flex-1 v-gap-s align-center">
                                <Label
                                    content={strings.blockNine.title}
                                    displayFont={true}
                                    size="xl"
                                    bold={true}
                                    color="black"
                                />  
                                <Label
                                    content={strings.blockNine.subtitle}
                                    mono={true}
                                    color="black"
                                    size="m"
                                    line="m"
                                    center={true}
                                />
                            </div>
                            {local?.inviteCode === "" && <button className="primary-button size-m bold" onClick={handleShowModal}>{strings.getStarted}</button>}
                            {local?.inviteCode !== "" && <button className="primary-button size-m bold" onClick={() => window.location.href = `https://app.trymaterialize.com/login?code=${local?.inviteCode}`}>Go to Dashboard</button>}
                            
                        </div>
                    }
                />
                <Section
                    bg="grey"
                    noGrow={true}
                    desktopBasis="75"
                    content={
                        <div className="flex vertical v-gap-xl">
                            <div className="flex vertical flex-1 pad-xl no-pad-bot v-gap-xl align-center">
                                <Label
                                    content={strings.blockTen.title}
                                    displayFont={true}
                                    size="xl"
                                    bold={true}
                                    color="black"
                                />  
                            </div>
                            <div className="flex horizontal flex-1 pad-xl no-pad-top v-gap-xl h-gap-m align-start mobile-vertical v-gap-xl">
                                <TestimonialCard
                                    image={John}
                                    name={strings.blockTen.testimonialOne.name}
                                    company={strings.blockTen.testimonialOne.company}
                                    testimonial={strings.blockTen.testimonialOne.testimonial}
                                    role={strings.blockTen.testimonialOne.role} />
                                <TestimonialCard
                                    image={Andres}
                                    name={strings.blockTen.testimonialTwo.name}
                                    company={strings.blockTen.testimonialTwo.company}
                                    testimonial={strings.blockTen.testimonialTwo.testimonial}
                                    role={strings.blockTen.testimonialTwo.role} />
                            </div>
                        </div>
                    }
                />
                <Section
                    noPadTop={true}
                    noGrow={true}
                    bg="white"
                    content={
                        <div className="flex vertical pad-xl v-gap-xl">
                            <div className="flex vertical flex-1 pad-xl v-gap-xl align-center no-pad-bot">
                                <Label
                                    content="Frequently asked questions."
                                    displayFont={true}
                                    size="xl"
                                    bold={true}
                                    color="black"
                                    center={true}
                                />  
                            </div>
                            <div className="flex vertical v-gap-m">
                                <div className="flex vertical v-gap-s h-gap-m mobile-vertical">
                                    {strings.blockEleven.questions.map((question, index) => 
                                        <CollapsableSection
                                            label={question.question}
                                            classes="card-light"
                                            content={
                                                <div className="flex horizontal pad-s no-pad-top">
                                                    <div className="flex blue-bg round-s vertical pad-s v-gap-xs">
                                                        <Label
                                                            content={question.answer}
                                                            mono={true}
                                                            size="xs"
                                                            line="xs"
                                                            bold={true}
                                                            color="black"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                />
                <Section
                    bg="grey"
                    content={
                        <div className="flex horizontal flex-1 pad-xl no-pad-bot align-end">
                            <div className="flex vertical flex-1 v-gap-l">
                                <div className="flex vertical flex-1">
                                    <Label
                                        mono={true}
                                        content={strings.footer.company}
                                        uppercase={true}
                                        secondary={true}
                                        size="xs"
                                        line="xs"
                                    />
                                    <Label
                                        mono={true}
                                        content={strings.footer.address}
                                        uppercase={true}
                                        secondary={true}
                                        size="xs"
                                        line="xs"
                                    />
                                    <Label
                                        mono={true}
                                        content={strings.footer.email}
                                        uppercase={true}
                                        secondary={true}
                                        size="xs"
                                        onClick={() => window.location.href = "mailto:info@trymaterialize.com"}
                                    />
                                </div>
                            </div>
                            <div className="flex vertical flex-1 v-gap-m">
                                <div className="flex vertical flex-1">
                                    <Label
                                        mono={true}
                                        content={strings.footer.linkedin}
                                        uppercase={true}
                                        secondary={true}
                                        size="xs"
                                        line="xs"
                                        onClick={() => window.open("https://www.linkedin.com/company/trymaterialize", "_blank")}
                                        right={true}
                                    />
                                    <Label
                                        mono={true}
                                        content={strings.footer.twitter}
                                        uppercase={true}
                                        secondary={true}
                                        size="xs"
                                        line="xs"
                                        onClick={() => window.open("https://twitter.com/trymaterialize", "_blank")}
                                        right={true}
                                    />
                                </div>
                                <div className="flex vertical flex-1">
                                    <Label
                                        mono={true}
                                        content={"Terms of Use"}
                                        uppercase={true}
                                        secondary={true}
                                        size="xs"
                                        line="xs"
                                        onClick={() => window.open("https://www.trymaterialize.com/terms-of-use", "_blank")}
                                        right={true}
                                    />
                                    <Label
                                        mono={true}
                                        content={"Privacy Policy"}
                                        uppercase={true}
                                        secondary={true}
                                        size="xs"
                                        line="xs"
                                        onClick={() => window.open("https://www.trymaterialize.com/privacy-policy", "_blank")}
                                        right={true}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
            {local?.cookies === "unset" && (
                <div id="cookie-banner">
                    <div className="blue-bg flex horizontal mobile-vertical pad-xl h-gap-xl v-gap-m space-between align-center">
                        <div className="flex vertical v-gap-xs">
                            <Label content="We value your privacy" size="l" bold={true} line="l" />
                            <Label content='We use cookies to enhance your browsing experience and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.' size="s" mono={true} line="s" />
                        </div>
                        <div className="flex horizontal h-gap-m mobile-width-100">
                            <Button label="Customize" secondary={true} classes="blue-bg" large={true} onClick={handleShowCookieModal} mobileFlex={true} />
                            <Button label="Accept All" large={true} classes="yellow-bg" onClick={() => handleSetCookies("all")} mobileFlex={true} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Landing;