import React, { } from 'react';
import './style/Landing.css';
import Section from '../component/block/Section';
import Wordmark from '../media/materialize-logo-wordmark-black.png';
import Label from '../component/block/Label';
const Terms = () => {
    const stringBlocks = [
        {
            title: "Welcome to Materialize",
            content: "Welcome to Materialize, an innovative platform by Harper AI, Inc. Here, we aim to transform the way manufacturers manage obsolete inventory and uncover valuable inventory insights. By using our services, you tap into a world where your excess stock transforms into opportunity and data-driven decisions become second nature. Thank you for choosing Materialize—where your next inventory move is powered by AI-driven clarity."
        },
        {
            title: "Acceptance of Terms",
            content: "By accessing or using our platform, Materialize, in any way, whether by visiting our website at trymaterialize.com, uploading content, or merely browsing, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use. These terms constitute a legally binding agreement between you and Harper AI, Inc. If you do not agree with any part of these terms, you must not use our services."
        },
        {
            title: "Description of Service",
            content: "Materialize offers two main features: a platform where manufacturers can list obsolete or excess inventory for sale, and a tool that analyzes inventory data to provide insights. For manufacturers looking to clear space or recoup investment from unsold goods, our platform facilitates the connection to secondary marketplaces, handling the sale and shipping process. Additionally, we provide a service for users to submit their inventory data, upon which we deliver detailed reports. These reports are tailored to show trends, usage patterns, and potential forecasts, designed to aid in making informed inventory decisions. Our aim is to help users manage their inventory more efficiently and with better outcomes."
        },
        {
            title: "User Accounts",
            content: "Creating an account may be necessary to access certain features of Materialize. When creating your account, you agree to provide true, accurate, and complete information about yourself and to update this information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account."
        },
        {
            title: "User Obligations and Conduct",
            content: "Users of Materialize are expected to use the platform responsibly. Actions such as uploading unauthorized content, infringing on others' rights, attempting to breach service security, or disrupting the platform's operations in any manner are strictly prohibited."
        },
        {
            title: "Content Ownership and Permissions",
            content: "You retain ownership of the content you upload to Materialize. By providing content, you grant Harper AI, Inc. a worldwide, non-exclusive license to use, reproduce, and display such content in connection with the service provided by Materialize."
        },
        {
            title: "Sales and Revenue Sharing",
            content: "For items that are sold through our platform, we operate on a revenue-sharing basis. This means that when an item is sold, the revenue from that sale is divided between the manufacturer (you) and Harper AI, Inc. The specific percentage of revenue share will depend on the agreed terms for each transaction. We aim to make the process transparent and efficient, ensuring you are fully informed of any fees and the exact revenue share you will receive before you list an item. Payments will be processed and issued in accordance with the terms agreed upon for each sale, and we provide support to facilitate smooth shipping of sold items."
        },
        {
            title: "Disclaimer of Warranties; Limitation of Liability",
            content: "Materialize and all related services are provided on an 'as is' and 'as available' basis without any warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability or fitness for a particular purpose. Harper AI, Inc. does not warrant that the service will be uninterrupted or error-free. In no event shall Harper AI, Inc. be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of Materialize, whether or not such damages were foreseeable."
        },
        {
            title: "Modifications to the Terms or the Service",
            content: "Harper AI, Inc. reserves the right, at its sole discretion, to modify or replace these Terms at any time. When changes are made, we will provide notice through the service, sending you an email, and/or by some other means. It is your responsibility to check the Terms of Use periodically for changes. Your continued use of the service following the posting of any changes to the Terms constitutes acceptance of those changes."
        },
        {
            title: "General Terms",
            content: "These Terms constitute the entire agreement between you and Harper AI, Inc. regarding Materialize. They are governed by the laws of Delaware, without regard to conflict of law principles. Should any part of these Terms be held invalid or unenforceable, the remaining portions continue in full effect."
        },
        {
            title: "Contact Information",
            content: "For any questions regarding these Terms or the services provided by Materialize, please contact info@trymaterialize.com."
        }
    ]

    const contentBlock = (index, title, content) => {
        return (
            <div key={index} className="flex vertical v-gap-s">
                <Label content={title} displayFont={true} bold={true} size="l" />
                <Label
                    content={content}
                    mono={true}
                    size="s"
                    line="s"
                    secondary={true}
                />
            </div>
        );
    }

    return (
        <div className="flex vertical full-screen overflow-y-scroll fade-in">
            <Section
                bg="yellow"
                noPadTop={true}
                noPadBot={true}
                header={true}
                content={
                    <>
                        <div id="home" className="yellow-bg flex horizontal flex-1 space-between pad-xl">
                            <img src={Wordmark} alt="Materialize Wordmark" className="wordmark-image" onClick={() => window.location.href = "/"} />
                        </div>
                    </>
                }
            />
            <Section
                bg="white"
                content={
                    <div className="flex vertical pad-xl v-gap-display">
                        <Label content="Terms of Use" displayFont={true} bold={true} size="xl" />
                        {stringBlocks.map((block, index) => {
                            return contentBlock(index, block.title, block.content);
                        })}
                    </div>
                } />
        </div>
    );
};

export default Terms;