import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './page/Landing';
import Terms from './page/Terms';
import Privacy from './page/Privacy';

function App() {
  window.recaptchaOptions = {
    useRecaptchaNet: true,
    enterprise: true,
  };

  return (
    <Router>
      <Routes>
        <Route key={"/"} path={"/"} element={<Landing />} />
        <Route key={"/terms-of-use"} path={"/terms-of-use"} element={<Terms />} />
        <Route key={"/privacy-policy"} path={"/privacy-policy"} element={<Privacy />} />
        <Route key={"/:page"} path={"/:page"} element={<Landing />} />
      </Routes>
    </Router>
  );
}

export default App;