import './style/CollapsableSection.css';
import React, {useState} from 'react';
import { } from '../../redux/localSlice';
import Label from './Label';
import Icon from './Icon';
import DownCaret from '../../media/down-caret-24@3x.png';
import Plus from '../../media/plus-24@3x.png';
import Minus from '../../media/minus-24@3x.png';

const CollapsableSection = ({ label, content, collapsed = true, classes = "" }) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    const handleClick = () => {
        // Implement the collapse/expand functionality
        setIsCollapsed(!isCollapsed);
    }
    return (
        <div className={`${isCollapsed ? "collapsed" : "expanded"} flex grow-0 shrink-1 basis-50 vertical clickable secondary ${classes}`}>
            <div className={`flex grow-0 shrink-1 basis-auto horizontal pad-s align-center space-between`} onClick={handleClick}>
                <Label content={label} size="m" bold={true} displayFont={true} color="black" classes="faq-title" />
                <Icon icon={isCollapsed ? Plus : Minus} size={12} rotate={!isCollapsed ? "180" : "0"} color="black" />
            </div>
            {!isCollapsed &&  content}
        </div>
    );
};

export default CollapsableSection;