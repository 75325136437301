import React, { } from 'react';
import './style/Landing.css';
import Section from '../component/block/Section';
import Wordmark from '../media/materialize-logo-wordmark-black.png';
import Arrow from '../media/arrow-down-right-64@3x.png';
import Label from '../component/block/Label';
import Icon from '../component/block/Icon';
const Terms = () => {
    const stringBlocks = [
        {
            title: "Introduction",
            content: `Welcome to "Materialize", brought to you by Harper AI, Inc. Our service, found at trymaterialize.com, is designed to provide detailed inventory analysis and help businesses manage unused inventory through resale. This Privacy Policy outlines how we collect, use, and protect your information when you use our services.
            
            Use of "Materialize" signifies your acceptance of this Privacy Policy. If you do not agree with our practices, please refrain from using our services. As our features expand, we may revise this policy. We will notify you of significant changes through our website or by email.`
        },
        {
            title: "Effective Date and Changes to the Policy",
            content: `This Privacy Policy is effective as of May 8th, 2024. Updates and Changes: Harper AI, Inc. reserves the right to update or modify this policy at any time. Changes will take effect immediately upon posting to our website. We encourage you to review this policy periodically to stay informed about how we are protecting the information we collect. If we make significant changes, we will notify you either through the website interface or via email, before the changes become effective. Your continued use of "Materialize" after any changes signifies your acceptance of our updated policy.`
        },
        {
            title: `Information We Collect`,
            content: `At Harper AI, Inc., we collect two types of information through our "Materialize" service: information you provide to us directly and information that is collected automatically as you use our services.`
        },
        {
            title: `User-Provided Information`,
            content: `When you use "Materialize", you may provide us with personal information such as your name, email address, and details about your inventory, including images and descriptions of items for resale. This also includes any other information necessary to facilitate our resale service or inventory analysis.`
        },
        {
            title: `Automatically Collected Information`,
            content: `As you navigate and interact with "Materialize", certain information about your device and your use of our services is collected automatically. This includes:`,
            bullets: [
                {
                    title: "Log Information",
                    content: `Details of your visits to our website, including traffic data, location data, logs, and the resources that you access.`
                },
                {
                    title: "Device Information",
                    content: `Information about the computer or mobile device you use to access our services, including the hardware model, operating system, unique device identifiers, and mobile network information.`
                },
                {
                    title: "Usage Data",
                    content: `Information about how you use "Materialize", which helps us to improve our service and understand user patterns.`
                }
            ]
        },
        {
            title: `Use of Cookies and Other Tracking Technologies`,
            content: `"Materialize" uses cookies and similar tracking technologies to measure and track the activity on our services and hold certain information. Cookies help us refine our service based on user preferences and improve your experience. You have the option to refuse cookies through your web browser settings, but this may affect certain functionalities of our services.`
        },
        {
            title: `Use of Information`,
            content: `The information we collect through "Materialize" supports the core functionality of our service and allows us to offer and improve our features. Specifically, we use your information to:`,
            bullets: [
                {
                    title: "Facilitate Transactions",
                    content: `We use the information you provide to facilitate the resale of items, including processing transactions, generating shipping labels, and coordinating with buyers.`
                },
                {
                    title: `Improve Our Service`,
                    content: `Automatically collected data helps us understand how our users engage with "Materialize", enabling us to enhance usability, introduce new features, and optimize our service.`
                },
                {
                    title: `Inventory Analysis`,
                    content: `We analyze provided inventory data to offer insights, identify trends, and generate reports that assist you in managing your inventory more effectively.`
                },
                {
                    title: `Customer Support`,
                    content: `Your information allows us to respond to your inquiries, provide the support you need, and communicate with you about our services.`
                },
                {
                    title: `Legal Compliance`,
                    content: `We may use your information to comply with applicable laws, respond to lawful requests by public authorities, and enforce our terms and policies.`
                }
            ]
        },
        {
            title: `Marketing and Communication`,
            content: `With your consent, we may use your information to send you promotional messages, marketing materials, and other communications related to "Materialize". You have the right to withdraw consent at any time, either by using the unsubscribe link in emails or contacting us directly.`
        },
        {
            title: `Information Sharing`,
            content: `We do not sell your personal information to third parties. Information may be shared with third-party platforms to the extent necessary for providing our services, such as payment processing and shipping logistics.`
        },
        {
            title: `Information Sharing and Disclosure`,
            content: `Harper AI, Inc. values your privacy. This section explains our information sharing practices to assure you of the confidentiality and integrity of your personal data.`
        },
        {
            title: `Sharing With Service Providers`,
            content: `We engage third-party companies and individuals ("Service Providers") to facilitate our service, perform service-related actions, or assist us in analyzing how our service is used. These Service Providers have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.`
        },
        {
            title: `Legal Requirements and Law Enforcement`,
            content: `Harper AI, Inc. may disclose your Personal Information in the good faith belief that such action is necessary to:`,
            bullets: [
                {
                    title: `Comply with a legal obligation or court order.`,
                },
                {
                    title: `Protect and defend the rights or property of Harper AI, Inc.`,
                },
                {
                    title: `Prevent or investigate possible wrongdoing in connection with the service.`,
                },
                {
                    title: `Protect the personal safety of users of the service or the public.`,
                },
                {
                    title: `Protect against legal liability.`,
                }
            ]
        },
        {
            title: `Business Transfers`,
            content: `In the event that Harper AI, Inc. undergoes a business transition, such as a merger, acquisition by another company, or sale of all or a portion of its assets, your Personal Information might be among the assets transferred. You will be notified via email and/or a prominent notice on our website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.`
        },
        {
            title: `Aggregated Data`,
            content: `Harper AI, Inc. may share aggregated, non-personally identifying information with partners or use it for research purposes. This aggregated data cannot be used to identify individuals and is used to help us improve our services and understand user trends.`
        },
        {
            title: `Data Security`,
            content: `At Harper AI, Inc., safeguarding your personal information is a top priority. We employ a variety of technical, administrative, and physical measures designed to protect your information from unauthorized access, disclosure, use, alteration, or destruction.`
        },
        {
            title: `Security Measures`,
            bullets: [
                {
                    title: `Encryption`,
                    content: `We use industry-standard encryption technologies when transferring and receiving consumer data exchanged with our site.`
                },
                {
                    title: `Secure Servers`,
                    content: `Information is stored on secure servers, safeguarded against unauthorized access.`
                },
                {
                    title: `Access Control`,
                    content: `Access to personal information is strictly limited to employees, contractors, and agents who need to know this information in order to operate, develop, or improve our services. They are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.`
                },
                {
                    title: `Regular Audits`,
                    content: `Our systems and security practices are regularly reviewed and updated to improve our service and security measures.`
                }
            ]
        },
        {
            title: `Data Breach Procedures`,
            content: `Despite our best efforts, no method of transmission over the Internet or method of electronic storage is 100% secure. However, should any breach of personal data occur, Harper AI, Inc. is committed to promptly responding to and managing any identified security incidents. We will notify affected individuals in compliance with applicable laws and regulations, and take all necessary steps to mitigate any potential harm.`
        },
        {
            title: `User Rights`,
            content: `Harper AI, Inc. recognizes that you have rights over your personal information and provides you with the following options regarding your data:`,
            bullets: [
                {
                    title: `Access and Update Your Information`,
                    content: `You have the right to access, review, and request updates to your personal information at any time. If you wish to access or amend any personal data we hold about you, please contact us.`
                },
                {
                    title: `Deletion of Your Personal Information`,
                    content: `You have the right to request that we delete any of your personal information that we have collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.`
                },
                {
                    title: `Data Portability`,
                    content: `Upon request, we will provide you with a copy of your personal information in a structured, commonly used, and machine-readable format, so you can manage and move it.`
                },
                {
                    title: `Opt-out of Data Use or Collection`,
                    content: `You may opt-out of the collection or use of your personal information by us. This includes opting out of marketing communications or other targeted advertising. Please note that opting out of certain uses may impact your experience with our services.`
                },
                {
                    title: `Withdraw Consent`,
                    content: `If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. This will not affect the lawfulness of the processing before your consent withdrawal.`
                },
                {
                    title: `Non-Discrimination`,
                    content: `Harper AI, Inc. will not discriminate against you for exercising any of your privacy rights. This means we will not deny you goods or services, charge you different prices, or provide a different level or quality of goods or services just because you exercised your rights.`
                },
                {
                    title: `How to Exercise Your Rights`,
                    content: `To exercise any of the rights described above, please contact us using the contact information provided in this policy. We may need to verify your identity before processing your request.`
                }
            ]
        },
        {
            title: `Cookies and Other Tracking Technologies`,
            content: `Harper AI, Inc. utilizes cookies and similar tracking technologies on the "Materialize" platform to enhance and personalize your experience, as well as to gather usage data that helps us improve our services. Cookies are small data files stored on your device that help websites remember information about your visit. They can improve your experience by saving browsing preferences and decreasing login times. This is how we use them:`,
            bullets: [
                {
                    title: `Essential Cookies`,
                    content: `These are necessary for the operational functionality of our services, such as accessing secure areas.`
                },
                {
                    title: `Performance and Analytics Cookies`,
                    content: `These cookies collect information about how you use "Materialize," allowing us to improve how our services perform. For example, they show us what pages are most frequently visited and help us record any difficulties you have with our services.`
                },
                {
                    title: `Functional Cookies`,
                    content: `These enhance your experience by remembering your preferences and settings. For instance, they can remember your username or language selection.`
                }
            ]
        },
        {
            title: `Your Choices`,
            content: `You have the option to control and limit cookies and similar technologies through your browser settings. You can configure your browser to reject all cookies or to indicate when a cookie is being sent. However, if you do not accept essential cookies, you might not be able to use some portions of our service.`
        },
        {
            title: `Other Tracking Technologies`,
            content: `Besides cookies, we may use web beacons, pixel tags, and other tracking technologies to improve our service and your experience. These technologies help us determine engagement with our emails and website content, enabling us to tailor our communications and content to better suit your interests.`
        },
        {
            title: `Third-party Links and Services`,
            content: `The "Materialize" website and services provided by Harper AI, Inc. may include links to third-party websites, plug-ins, services, social networks, or applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements or practices.
            
            Before engaging with any third-party services linked through "Materialize," we encourage you to read the privacy policy of each website you visit. Please be aware that those third parties have their own privacy policies and that we do not accept any responsibility or liability for these policies or for any personal data that may be collected through these websites or services, such as contact and location data.
            
            It’s important for you to understand the privacy practices of these third parties and to make informed decisions when utilizing their services linked to our platform.
            `
        },
        {
            title: `Contact Information`,
            content: `At Harper AI, Inc., your privacy matters to us. If you have any questions, concerns, or requests regarding this Privacy Policy, our use of your personal information, or your privacy rights, we welcome you to reach out to us at info@trymaterialize.com.`
        }
    ]

    const contentBlock = (index, block) => {
        return (
            <div key={index} className="flex vertical v-gap-xl">
                <div className="flex vertical v-gap-s">
                    <Label content={block.title} displayFont={true} bold={true} size="l" />
                    <Label
                        content={block.content}
                        mono={true}
                        size="s"
                        line="s"
                        classes="preline"
                        secondary={true}
                    />
                </div>
                {block.bullets && <div className="flex vertical v-gap-xl pad-s no-pad-top no-pad-bot">
                    {block.bullets && block.bullets.map((bullet, index) => {
                        return (
                            <div key={index} className="flex horizontal h-gap-l align-center">
                                <Icon icon={Arrow} color='#3F4A74' size={20} />
                                <div className="flex vertical v-gap-xs">
                                    <Label content={bullet.title} displayFont={true} bold={true} size="m" />
                                    {bullet.content && <Label
                                        content={bullet.content}
                                        mono={true}
                                        size="s"
                                        line="s"
                                        classes="preline"
                                        secondary={true}
                                    />}
                                </div>
                            </div>
                        );
                    })
                    }
                </div>}
            </div>
        );
    }

    return (
        <div className="flex vertical full-screen overflow-y-scroll fade-in">
            <Section
                bg="yellow"
                noPadTop={true}
                noPadBot={true}
                header={true}
                content={
                    <>
                        <div id="home" className="yellow-bg flex horizontal flex-1 space-between pad-xl">
                            <img src={Wordmark} alt="Materialize Wordmark" className="wordmark-image" onClick={() => window.location.href = "/"} />
                        </div>
                    </>
                }
            />
            <Section
                bg="white"
                content={
                    <div className="flex vertical pad-xl v-gap-display">
                        <Label content="Privacy Policy" displayFont={true} bold={true} size="xl" />
                        {stringBlocks.map((block, index) => {
                            return contentBlock(index, block);
                        })}
                    </div>
                } />
        </div>
    );
};

export default Terms;